import React from "react";

import "../index.css";
import logo from "../assests/apxlogo_white.png";
const Loading = () => {
   document.title = "Loading...";
  const C = () => {
    return (
      <div className="loader">
        <div className="loader_divs">
          <div className="logo">
            <img src={logo} alt="" />
          </div>

          <h2 className="text">Loading...</h2>
        </div>
      </div>
    );
  };
  return (
    <>
      <C />
    </>
  );
};

export default Loading;
