import React from "react";
import { useEffect } from "react";
import ReactPlayer from "react-player";

const ValorantTeam = () => {
  useEffect(() => {
    document.title = "Valorant Teams";
  }, []);
  return (
    <div className="valorant_team_video">
      <ReactPlayer
        controls
        autoPlay
        url="https://www.facebook.com/theapexbattalion.esports/videos/484159186997344"
      />
    </div>
  );
};

export default ValorantTeam;
