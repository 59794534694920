import { Routes, Route, BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { lazy, Suspense } from "react";
import Loading from "./components/Loading";
import ValorantTeam from "./components/ValorantTeam";
const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));
const Home = lazy(() => import("./components/Home"));
const PagePlans = lazy(() => import("./components/pages/PagePlans"));
const PageTeams = lazy(() => import("./components/pages/PageTeams"));
const PageCommunity = lazy(() => import("./components/pages/PageCommunity"));
const PageWatch = lazy(() => import("./components/pages/PageWatch"));
const PageComingSoon = lazy(() => import("./components/pages/PageComingSoon"));
const PageJoinUs = lazy(() => import("./components/pages/PageJoinUs"));
const PageContact = lazy(() => import("./components/pages/PageContact"));
const PageTeamMembers = lazy(() =>
  import("./components/pages/PageTeamMenmbers")
);
const PageTournaments = lazy(() =>
  import("./components/pages/PageTournaments")
);
const PageGameDay = lazy(() => import("./components/pages/PageGameDay"));

const PageNotFound = lazy(() => import("./components/PageNotFound"));
const PagePlansSub = lazy(() => import("./components/pages/PagePlansSubs"));
const PagePastTournaments = lazy(() =>
  import("./components/pages/PagePastTournaments")
);
const PageResults = lazy(() => import("./components/pages/PageResults"));
const PageAboutUs = lazy(() => import("./components/pages/PageAboutUs"));
const PageAchievement = lazy(() =>
  import("./components/pages/PageAchievement")
);
const PageStrore = lazy(() =>
  import("./components/pages/PageStrore")
);
// import LookingForTeam from "./components/LooingForTeam";
function App() {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home time={Date.now() + 6000} />} />
          <Route path="/services" element={<PagePlans />} />
          <Route path="/teams" element={<PageTeams />} />
          <Route path="/community" element={<PageCommunity />} />
          <Route path="/watch" element={<PageWatch />} />
          <Route path="/store" element={<PageStrore />} />
          <Route path="/join_us" element={<PageJoinUs />} />
          <Route path="/contact_us" element={<PageContact />} />
          <Route path="/coming_soon" element={<PageComingSoon />} />
          <Route path="/board_of_apex" element={<PageComingSoon />} />
          <Route path="/achievements" element={<PageAchievement />} />
          <Route path="/team_members_valorant" element={<ValorantTeam />} />

          <Route path="/team_members_:team" element={<PageTeamMembers />} />
          <Route path="/buy_:sub" element={<PagePlansSub />} />

          <Route path="/tournaments" element={<PageTournaments />} />
          <Route path="/streaming" element={<PageGameDay />} />
          <Route path="/past_tournaments" element={<PagePastTournaments />} />
          <Route path="/results" element={<PageResults />} />
          <Route path="/about_us" element={<PageAboutUs />} />

          <Route path="/loading" element={<Loading />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
